import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'
import masterTags from '../masterTags.json'
import type { RootState } from '../store'
import { SearchResponse, SearchResult } from 'types'

function checkValue(v: number | string | null | any[]): boolean {
  return (
    typeof v === 'number' ||
    (typeof v === 'string' && v.length > 0) ||
    (Array.isArray(v) && v.length > 0) ||
    v !== undefined
  )
}

const getNonEmptyParams = (params: SearchParams): Partial<SearchParams> => {
  return _.pickBy(params, (v: any) => checkValue(v))
}

const getSearchResults = async (
  { append = false }: { append?: boolean },
  thunkAPI: any,
): Promise<SearchResponse> => {
  const state = thunkAPI.getState()
  const params: SearchParams = state.search.params
  const page: number = append ? state.search.nextPage : 1
  let validParams: Partial<ExtendedSearchParams> = getNonEmptyParams(params) // Removes null values

  const team_id = state.user.primary_team_id

  if (team_id) {
    validParams.team_id = team_id
  }

  const { limit } = state.search
  const response = await axios.get<SearchResponse>('/api/directory/social-accounts', {
    params: { ...validParams, page, limit, talent_sheet: true },
  })

  return {
    ...response.data,
    append,
    team_id,
  }
}

const getAiResults = async (
  { append = false }: { append?: boolean },
  thunkAPI: any,
): Promise<SearchResponse> => {
  const state = thunkAPI.getState()
  const params: SearchParams = state.search.params
  const page: number = append ? state.search.nextPage : 1
  let validParams: Partial<ExtendedSearchParams> = getNonEmptyParams(params) // Removes null values

  const team_id = state.user.primary_team_id

  if (team_id) {
    validParams.team_id = team_id
  }

  const { limit } = state.search
  console.log('Get ai results...')
  const response = await axios.post<SearchResponse>('/api/advanced-filters-ai', {
    ...validParams,
    page,
    limit,
    talent_sheet: true,
  })
  console.log(response)

  return {
    ...response.data,
    append,
    team_id,
  }
}

export const getAiResultsAction = createAsyncThunk('search/getAiResults', getAiResults)

export const performSearch = createAsyncThunk('search/performSearch', getSearchResults)

export const performUpdate = createAsyncThunk('search/performUpdate', getSearchResults)

interface Tag {
  label: string
  value: string
}

interface SearchParams {
  age_minimum: number | null
  age_maximum: number | null
  gender: string | null
  followers_min: number | null
  followers_max: number | null
  engagement_rate_min: number | null
  engagement_rate_max: number | null
  us_audience_share_min: number | null
  audience_age_range: string | null
  audience_age_min: number | null
  audience_age_max: number | null
  min_age_share: number | null
  platform: string | null
  avg_reels_plays_min: number | null
  avg_reels_plays_max: number | null
  tags: string[]
  limit: number | null
  page: number | null
  target_gender: string | null
  target_min_gender_share: number | null
  name_search: string | null
  [key: string]: string | number | null | string[] | undefined
}

type ExtendedSearchParams = SearchParams & { team_id?: string }

interface SearchState {
  params: SearchParams
  selectedTags: Tag[]
  availableTags: string[]
  results: SearchResult[] // Replace 'any' with the specific type of your results
  loading: 'initial' | 'pending' | 'appending' | 'idle'
  nextPage: number
  limit: number
  hasMoreResults: boolean
  filtersApplied: boolean
  total_count: number | null
  visiblePopover: string | null
  searchTeam: string | null
  filtersTouched: boolean
  sortBy: string
  sortOrder: 'asc' | 'desc'
}

const initialParams: SearchParams = {
  age_minimum: null,
  age_maximum: null,
  gender: null,
  followers_min: null,
  followers_max: null,
  engagement_rate_min: null,
  engagement_rate_max: null,
  us_audience_share_min: null,
  audience_age_range: null,
  audience_age_min: null,
  audience_age_max: null,
  min_age_share: null,
  platform: 'instagram',
  avg_reels_plays_min: null,
  avg_reels_plays_max: null,
  tags: [],
  limit: null,
  page: null,
  target_gender: null,
  target_min_gender_share: null,
  name_search: null,
}

const initialState: SearchState = {
  params: {
    ...initialParams,
  },
  selectedTags: [],
  availableTags: masterTags,
  results: [],
  loading: 'initial',
  nextPage: 1,
  limit: 300,
  hasMoreResults: true,
  filtersApplied: false,
  total_count: null,
  visiblePopover: null,
  searchTeam: null,
  filtersTouched: false,
  sortBy: 'followers',
  sortOrder: 'desc',
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setVisiblePopover: (state, action: PayloadAction<string | null>) => {
      state.visiblePopover = action.payload
    },
    updateParams: (state, action: PayloadAction<Partial<SearchParams>>) => {
      const newParams = { ...state.params, ...action.payload }
      state.params = newParams
      state.filtersApplied = !_.isEqual(newParams, initialParams)
      state.filtersTouched = true
    },
    setSelectedTags: (state, action: PayloadAction<Tag[]>) => {
      console.log('setSelectedTags')
      state.selectedTags = action.payload
      state.params.tags = action.payload.map((t) => t.value)
      state.filtersTouched = true
    },
    resetParams: (state) => {
      state.params = { ...initialParams }
      state.filtersApplied = false
    },
    updateSearchPage: (state, action: PayloadAction<number>) => {
      state.nextPage = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload
    },
    setSortOrder: (state, action: PayloadAction<'asc' | 'desc'>) => {
      state.sortOrder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(performSearch.pending, (state, action) => {
        state.results = []
        state.loading = 'pending'
      })
      .addCase(performUpdate.pending, (state, action) => {
        state.loading = 'appending'
      })
      .addCase(performSearch.fulfilled, (state, { payload }) => {
        state.results = payload.results
        state.nextPage = payload.nextPage
        state.hasMoreResults = payload.hasMoreResults

        state.filtersApplied = !_.isEqual(
          getNonEmptyParams(state.params),
          getNonEmptyParams(initialParams),
        )
        if (!_.isEmpty(getNonEmptyParams(state.params))) {
          state.total_count = payload.query_info?.total || null
        } else {
          state.total_count = null
        }
        state.loading = 'idle'

        state.searchTeam = payload.team_id || null
      })
      .addCase(performUpdate.fulfilled, (state, { payload }) => {
        if (payload.append) {
          state.results = [...state.results, ...payload.results]
        } else {
          state.results = payload.results
        }
        state.nextPage = payload.nextPage
        state.hasMoreResults = payload.hasMoreResults
        state.loading = 'idle'
        state.searchTeam = payload.team_id || null
      })
  },
})

export const {
  updateParams,
  resetParams,
  setSelectedTags,
  updateSearchPage,
  setVisiblePopover,
  setSortBy,
  setSortOrder,
} = searchSlice.actions

const selectSearchResults = (state: RootState) => state.search.results

export const selectUniqueResults = createSelector(selectSearchResults, (results) =>
  _.uniqBy(results, (result) => `${result.social_account_id}-${result.influencer_id}`),
)

export default searchSlice.reducer
