import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import config from '../config'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { joinClassNames, getContrastingTextForBackground } from 'utils'
import { DNA } from 'react-loader-spinner'
import { Talent } from 'helpers/Talent'
import {
  setNote,
  addInfluencer,
  addInfluencerWithNoteAndSave,
  setCampaignDescription,
} from 'redux/userSheetSlice'
import RenderAISuggestedInfluencers from './RenderAISuggestedInfluencers'

const testDescription =
  'City Beauty—a clean, research-driven skincare brand made to help customers feel empowered, confident, and youthful — so their best selves can shine through. \n' +
  '\n' +
  "We're looking for creators who meet the following criteria: \n" +
  '- Age 35-45+ \n' +
  '- Female \n' +
  '- Interests: Beauty, Fashion, Skincare, Lifestyle, Moms with interests in Beauty/Skincare, Fitness with interests in Beauty/Skincare, \n' +
  `- PLUS if they focus on mature women content, anti-aging skincare, over 40 content, over 50 content, Uses CTA "comment LINK and i'll send you details", High link clicks over 800\n`

const TalentSheetAssistantModal = ({ showModal, handleClose, displaySettings }) => {
  const dispatch = useDispatch()

  const blankForm = {
    description: '',
  }

  const campaign_description = useSelector((state) => state.userSheet.campaign_description)

  const setDescription = (newDescription) => {
    dispatch(setCampaignDescription(newDescription))
  }

  const [suggestedInfluencers, setSuggestedInfluencers] = useState([])

  const [responsePending, setResponsePending] = useState(false)

  const [error, setError] = useState(null)

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)
  const user = useSelector((state) => state.user)

  const [explanations, setExplanations] = useState({})
  const [useExplanations, setUseExplanations] = useState({})

  // const handleExplanationChange = (id, value) => {
  //   setExplanations((prev) => ({ ...prev, [id]: value }))
  // }

  // const handleCheckboxChange = (id, checked) => {
  //   setUseExplanations((prev) => ({ ...prev, [id]: checked }))
  // }

  const talentSheet = useSelector((state) => state.userSheet)
  const talentSheetInfluencers = talentSheet.influencer_ids

  const handleGenerateSuggestions = async (e) => {
    e.preventDefault()

    if (campaign_description.length < 20) {
      setError('To find good matches, your description needs to be a bit longer')
      return
    } else {
      setError(null)
    }

    setResponsePending(true)

    try {
      const response = await axios.post(`/api/sheet-helper`, {
        description: campaign_description,
        included_influencers: talentSheetInfluencers,
      })

      if (response.data.error || !response.data.influencers) {
        setResponsePending(false)
        return
      }

      setSuggestedInfluencers(response.data.influencers)

      // create explanations object using influencer ids
      const newExplanations = response.data.influencers.reduce((acc, influencer) => {
        acc[influencer.id] = influencer.explanation
        return acc
      }, {})

      setExplanations(newExplanations)
    } catch (err) {
      console.log(err)
      setError(err.message)
    }
    setResponsePending(false)
  }

  const handleAddClick = (influencer) => {
    dispatch(
      addInfluencerWithNoteAndSave({
        influencer_id: influencer.id,
        note: useExplanations[influencer.id] ? explanations[influencer.id] : null,
      }),
    )
  }

  return (
    <Modal size="xl" show={showModal} onHide={handleClose}>
      <Modal.Body>
        <div className={joinClassNames(['container', 'bg-white', 'p-3'])}>
          <div className="row">
            <div className="col-12">
              <h4 className="text-black fs-1 text-center mb-3">Talent Sheet Assistant</h4>
            </div>
            <div className="col-12">
              <p
                style={{
                  fontSize: '1.1rem',
                }}
                className="text-black text-center mb-3"
              >
                Use the Talent Sheet Assistant to generate AI suggestions based on the campaign
                description you provide. The assistant uses your team's custom descriptions and
                tags to find matches, so ensuring that these are accurate will help you find the
                best matches.
              </p>
            </div>
            <div className={joinClassNames(['col-12', 'text-black', 'p-2'])}>
              <form onSubmit={handleGenerateSuggestions} className="w-100 mb-3">
                <div className="form-group mb-3">
                  <label htmlFor="descriptionTextarea" className="form-label">
                    Description:
                  </label>
                  <textarea
                    id="descriptionTextarea"
                    className="form-control"
                    placeholder={`Describe the type of talent you are looking for, and the system will look through your talent roster to find the best matches
`}
                    value={campaign_description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="10"
                  />
                </div>
                <div className="row w-100 d-flex justify-content-center">
                  <button
                    className="btn btn-bright text-white w-50"
                    style={{
                      height: '48px',
                    }}
                    type="submit"
                  >
                    Generate Suggestions
                  </button>
                </div>
              </form>
              <hr />

              {responsePending && (
                <div className="d-flex flex-column justify-content-center loading-icon mt-3">
                  <div className="w-100 text-center">
                    <DNA visible={true} height="80" width="80" ariaLabel="dna-loading" />
                  </div>
                  <p className="text-center fw-bold">
                    Please be patient, this could take a little while...
                  </p>
                </div>
              )}

              {error && (
                <div className="alert alert-danger mt-3" role="alert">
                  {error}
                </div>
              )}
              {!responsePending && suggestedInfluencers.length > 0 && (
                <div className="mt-3 w-100">
                  <h5 className="text-black fs-2 text-center my-3">Suggested Influencers</h5>
                  <RenderAISuggestedInfluencers
                    explanations={explanations}
                    setExplanations={setExplanations}
                    suggestedInfluencers={suggestedInfluencers}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TalentSheetAssistantModal
