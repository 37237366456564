import React, { useState } from 'react'
import {
  EuiModal,
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  useGeneratedHtmlId,
} from '@elastic/eui'
import { performSearch, selectUniqueResults, getAiResultsAction } from 'redux/searchSlice'
import { useDispatch, useSelector } from 'react-redux'
import DisplaySearchResults from './DisplaySearchResults'
import { Modal, Button } from 'react-bootstrap'
import { setModalShow } from 'redux/modalsSlice'
import SearchArea from './SearchArea'
import { getNonEmptyParams } from 'utils'
import axios from 'axios'
import Switch from './Switch'
import AISuggestionsForm from './AISuggestionsForm'

const AdvancedFiltersModal = ({
  talentSheet = {},
  handleAdd = (i) => {
    console.log('add', i)
  },
  handleAddMultiple = () => {},
}) => {
  const searchResults = useSelector((state) => selectUniqueResults(state))
  const show = useSelector((state) => state.modals.advancedFilters)
  const dispatch = useDispatch()

  const params = useSelector((state) => state.search.params)
  const user = useSelector((state) => state.user)

  const [isAiSearch, setIsAiSearch] = useState(false)

  const [suggestedInfluencers, setSuggestedInfluencers] = useState([])
  const [explanations, setExplanations] = useState({})

  const campaign_description = useSelector((state) => state.userSheet.campaign_description)

  const getAiResults = async () => {
    let validParams = getNonEmptyParams(params) // Removes null values

    const team_id = user.primary_team_id

    if (team_id) {
      validParams.team_id = team_id
    }

    const limit = 200

    const response = axios.post('/api/advanced-filters-ai', {
      ...validParams,
      limit,
      talent_sheet: true,
      description: campaign_description,
      included_influencers: talentSheet.influencer_ids,
    })

    return response
  }

  const setShow = (show) => dispatch(setModalShow({ modal: 'advancedFilters', show }))

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>Advanced Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <SearchArea />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="aiSearchCheckbox"
                    checked={isAiSearch}
                    onChange={() => setIsAiSearch(!isAiSearch)}
                  />
                  <label className="form-check-label fw-bold" htmlFor="aiSearchCheckbox">
                    Use AI with these filters to get suggestions
                  </label>
                </div>
              </div>
            </div>
            {isAiSearch ? (
              <>
                <AISuggestionsForm
                  suggestedInfluencers={suggestedInfluencers}
                  setSuggestedInfluencers={setSuggestedInfluencers}
                  getAIResponse={getAiResults}
                  explanations={explanations}
                  setExplanations={setExplanations}
                />
              </>
            ) : (
              <>
                <h3 className="mt-3 fs-3">Filtered Results:</h3>
                <DisplaySearchResults
                  results={searchResults}
                  handleAdd={handleAdd}
                  handleAddMultiple={handleAddMultiple}
                  exclude_ids={talentSheet.influencer_ids}
                  blankMessage="No results to show"
                />
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-bright-blue" onClick={handleClose}>
            Done
          </button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdvancedFiltersModal
