import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import config from '../config'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { joinClassNames, getContrastingTextForBackground } from 'utils'
import { DNA } from 'react-loader-spinner'
import { Talent } from 'helpers/Talent'
import { setNote, addInfluencer, addInfluencerWithNoteAndSave } from 'redux/userSheetSlice'

const RenderAISuggestedInfluencers = ({ suggestedInfluencers, explanations, setExplanations }) => {
  const dispatch = useDispatch()

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)
  const user = useSelector((state) => state.user)
  const talentSheet = useSelector((state) => state.userSheet)
  const talentSheetInfluencers = talentSheet.influencer_ids

  const [useExplanations, setUseExplanations] = useState({})

  const handleAddClick = (influencer) => {
    dispatch(
      addInfluencerWithNoteAndSave({
        influencer_id: influencer.id,
        note: useExplanations[influencer.id] ? explanations[influencer.id] : null,
      }),
    )
  }

  const handleExplanationChange = (id, value) => {
    setExplanations((prev) => ({ ...prev, [id]: value }))
  }

  const handleCheckboxChange = (id, checked) => {
    setUseExplanations((prev) => ({ ...prev, [id]: checked }))
  }

  return (
    <>
      {suggestedInfluencers.map((suggestedInfluencer, index) => {
        const influencer = influencers[suggestedInfluencer.id]

        if (!influencer) {
          return null
        }

        if (talentSheetInfluencers.includes(influencer.id)) {
          return null
        }

        const talent = new Talent(influencer, socials)
        return (
          <>
            <div key={`suggested-influencer-${suggestedInfluencer.id}`} className="row my-3">
              <div className="col-3">
                <a
                  href={`/public/profile/${influencer.id}/team/${user.primary_team_id}`}
                  target="_blank"
                >
                  <div className="d-flex">
                    <img
                      style={{ width: '75px' }}
                      src={talent.getPhotoUrl()}
                      alt={influencer.fullname}
                    />
                    <div className="ms-2 text-black fw-bold">{influencer.fullname}</div>
                  </div>
                </a>
              </div>
              <div className="col-6">
                <textarea
                  className="form-control"
                  value={explanations[influencer.id]}
                  onChange={(e) => handleExplanationChange(influencer.id, e.target.value)}
                  rows="3"
                />
                <div className="form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`useExplanation-${influencer.id}`}
                    checked={useExplanations[influencer.id] || false}
                    onChange={(e) => handleCheckboxChange(influencer.id, e.target.checked)}
                  />
                  <label
                    className="form-check-label fw-bold"
                    htmlFor={`useExplanation-${influencer.id}`}
                  >
                    Include this as a special note
                  </label>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <button
                  onClick={() => handleAddClick(influencer)}
                  className="btn btn-bright-blue px-3"
                  style={{
                    height: '48px',
                  }}
                >
                  Add +
                </button>
              </div>
            </div>
            <hr />
          </>
        )
      })}
    </>
  )
}

export default RenderAISuggestedInfluencers
