import React, { useState } from 'react'
import './switch.scss'
import { BsFillGrid3X3GapFill } from 'react-icons/bs'
import { BsListUl } from 'react-icons/bs'

const Switch = ({
  isRight: isLeft,
  setIsRight: setIsLeft,
  ChoiceLeft = <BsListUl />,
  ChoiceRight = <BsFillGrid3X3GapFill />,
  leftText = 'List View',
  rightText = 'Grid View',
}) => {
  return (
    <div className="switch-container">
      <button
        className={`switch-button ${isLeft ? 'active' : ''}`}
        onClick={() => setIsLeft(false)}
      >
        {ChoiceLeft}
        <span className="ms-1">{leftText}</span>
      </button>
      <button
        className={`switch-button ${!isLeft ? 'active' : ''}`}
        onClick={() => setIsLeft(true)}
      >
        {ChoiceRight}
        <span className="ms-1">{rightText}</span>
      </button>
    </div>
  )
}

export default Switch
