import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Navigate,
  Outlet,
  createRoutesFromElements,
  ScrollRestoration,
  Link,
} from 'react-router-dom'

import { fetchAllTags } from './redux/tagsSlice'
import { signOut, fetchUserStatus } from './redux/userSlice'
import { useDispatch, useSelector } from 'react-redux'

import NewAddTalent from 'NewAddTalent'
import CampaignDetails from './CampaignDetails'
import ContactForm from './ContactForm'
import CustomDescription from './CustomDescription'
import EditCollection from './EditCollection'
import EditTalentSheet from './EditTalentSheet'
import ForgotPassword from './ForgotPassword'
import LoadingContainer from './components/LoadingContainer'
import TPPNavbar from './Navbar'
import NewUserConfirm from './NewUserConfirm'
import NotFound from './NotFound'
import PublicCollection from './PublicCollection.jsx'
import PublicInfluencerProfile from './PublicInfluencerProfile'
import PublicTalentSheetRoster from './PublicTalentSheetRoster'
import RequestTalent from './RequestTalent'
import ResetPassword from './ResetPassword'
import Signin from './Signin'
import NewTalentSheets from 'NewTalentSheets'

import UserSettings from './UserSettings'
import { fetchBasicInfo } from './redux/allSheetsSlice'
import { fetchInfluencers } from './redux/influencerSlice'
import routes from './routes'
import { useEffect } from 'react'
import EditInfluencerPosts from 'EditInfluencerPosts'
import RequestChange from 'RequestChange'
import SheetAnalytics from 'SheetAnalytics'
import SheetClicks from 'SheetClicks'
import { fetchConversations } from 'redux/conversationSlice'
import ErrorWrapper from 'ErrorWrapper'
import { selectTeamSettings } from './redux/teamSlice'
import TalentSheetResponse from 'TalentSheetResponse'
import PublicTalentSheetController from 'PublicTalentSheetController'
import { Helmet } from 'react-helmet'
import { fetchSettings } from './redux/userSlice'
import AccountDisabled from 'AccountSuspended'
import AlternateCollections from 'AlternateCollections'
import TalentSheetPreview from 'TalentSheetPreview'
import RequestPortraitChange from 'RequestPortraitChange'
import NewEditTalentSheet from 'NewEditTalentSheet'
import NewInternalProfile from 'NewInternalProfile'
import NewNewSheetModal from 'NewNewSheetModalPage'
import NewEditCollection from 'NewEditCollection'
import NewPublicTalentRoster from 'NewPublicTalentRoster'
import NotificationModal from './components/NotificationModal'
import PublicTalentRoster from 'PublicTalentRoster'
import PublicRosterController from 'PublicRosterController'
import RateCardForm from 'components/RateCardForm'

function HomeRedirect() {
  return <Navigate to={'/talent-sheets'} />
}

function ProtectedRoute() {
  const user = useSelector((state) => state.user)
  if (!user.loaded) return null

  if (user.loaded && !user.authed) {
    return <Navigate to="/signin" />
  }

  if (user.authed && user.account_disabled) {
    return <Navigate to="/account-disabled" />
  }

  return <Outlet />
}

function Signout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(signOut())
  })

  return (
    <div className="container mt-4">
      <p>You have signed out.</p>
      <Link to="/signin">Sign in again</Link>
    </div>
  )
}

function Layout() {
  const user = useSelector((state) => state.user)

  const isAuthed = user.authed && !user.account_suspended

  return (
    <div>
      <Helmet>
        <title>Talent Pitch Pro</title>
      </Helmet>
      <ScrollRestoration />
      <div className="site-container">
        {user.notification && <NotificationModal />}
        {isAuthed && <TPPNavbar />}
        <Outlet />
      </div>
      <ToastContainer />
    </div>
  )
}

const Root = () => {
  const user = useSelector((state) => state.user)
  const teamSettings = useSelector(selectTeamSettings)

  const dispatch = useDispatch()

  useEffect(() => {
    // user has signed in and we need to fetch their data
    if (user.authed && !user.hasAdvancedSettings) {
      dispatch(fetchUserStatus())
    }

    if (user.authed && !user.noTeam) {
      dispatch(fetchInfluencers())
      dispatch(fetchAllTags())
      dispatch(fetchBasicInfo())
      dispatch(fetchConversations({ only_unread: false }))
      dispatch(fetchSettings())
    } else {
      dispatch(fetchUserStatus())
    }
  }, [dispatch, user.authed, user.noTeam])

  useEffect(() => {
    if (!user.authed) return
    const interval = setInterval(() => {
      dispatch(fetchConversations({ only_unread: true }))
    }, 1000 * 60 * 5)

    return () => clearInterval(interval)
  }, [dispatch, user.authed])

  return (
    <div>
      <Outlet />
    </div>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorWrapper />}>
      <Route path="/" element={<Layout />}>
        <Route path="/login" element={<Navigate to="/signin" />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetCode" element={<ResetPassword />} />
        <Route path="/new-user-confirm/:registrationCode" element={<NewUserConfirm />} />

        <Route path="/signout" element={<Signout />} />
        <Route
          path="/public/collection/view-all/:collectionId"
          element={<AlternateCollections />}
        />
        <Route path="/public/collection/:collectionId" element={<PublicCollection />} />
        <Route path="/public/talent-details/:teamId" element={<NewPublicTalentRoster />} />
        <Route path="/public/talent-roster/:teamId" element={<PublicRosterController />} />
        <Route
          path="/public/talent-sheet/:talentSheetId"
          element={<PublicTalentSheetController />}
        />
        <Route
          path="/public/talent-sheet-response/:talentSheetId"
          element={<TalentSheetResponse />}
        />
        <Route path="/public/profile/:influencerId" element={<PublicInfluencerProfile />} />
        <Route
          path="/public/profile/:influencerId/team/:teamId"
          element={<PublicInfluencerProfile />}
        />
        <Route
          path="/public/talent-sheet/roster-view/:talentSheetId"
          element={<PublicTalentSheetRoster />}
        />
        <Route path="/loading" element={<LoadingContainer />} />
        <Route path="/account-disabled" element={<AccountDisabled />} />

        <Route element={<ProtectedRoute />}>
          <Route index element={<HomeRedirect />} />
          <Route path="/influencers/profile/:influencerId" element={<NewInternalProfile />} />
          <Route path="/request/:influencerId" element={<RequestChange />} />
          <Route
            path="/request/portrait-change/:influencerId"
            element={<RequestPortraitChange />}
          />
          <Route path="/talent/:influencerId/edit-posts" element={<EditInfluencerPosts />} />
          <Route path="/talent/edit-description/:influencerId" element={<CustomDescription />} />
          <Route path="/talent/edit-rate-card/:influencerId" element={<RateCardForm />} />
          <Route path="/talent-sheet-create" element={<NewNewSheetModal />} />
          <Route
            path="/talent-sheet/:talentSheetId"
            element={<NewEditTalentSheet sheetType="talent" />}
          />
          <Route
            path="/creator-call-response/:castingCallId"
            element={<NewEditTalentSheet sheetType="casting_call_response" />}
          />
          <Route
            path="/advanced-creator-call/:castingCallId"
            element={<EditTalentSheet sheetType="casting_call_response" />}
          />
          <Route
            path="/advanced-edit-filters/:talentSheetId"
            element={<EditTalentSheet sheetType="talent" />}
          />
          <Route
            path="/creator-call-response/preview/:castingCallId"
            element={<TalentSheetPreview />}
          />
          <Route path="/talent-sheet" element={<EditTalentSheet sheetType="talent" />} />
          <Route path="/talent-sheets" element={<NewTalentSheets />} />
          <Route path="/talent-sheets/statistics/:sheetId" element={<SheetClicks />} />
          <Route path="/talent-sheets/statistics" element={<SheetAnalytics />} />
          {routes.map((route, i) => {
            return <Route key={`route-${i}`} path={route.route} element={route.element} />
          })}
          <Route
            path="/campaign/:talentSheetId"
            element={<EditTalentSheet sheetType="campaign" />}
          />
          <Route path="/campaign/view/:talentSheetId" element={<CampaignDetails />} />
          <Route path="/campaign" element={<EditTalentSheet sheetType="campaign" />} />
          <Route path="/collection" element={<EditCollection />} />
          <Route path="/collection/:collectionId" element={<NewEditCollection />} />
          <Route path="/configure" element={<UserSettings />} />
          <Route path="/contact-tpp" element={<ContactForm />} />
          <Route path="/request-talent" element={<RequestTalent />} />
          <Route path="/add-talent" element={<NewAddTalent />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Route>,
  ),
)

export default function Main() {
  return <RouterProvider router={router} />
}
