import { useEffect, useState, useMemo } from 'react'
import {
  EuiIcon,
  EuiTitle,
  EuiPageTemplate,
  EuiButton,
  EuiSpacer,
  EuiTextArea,
  EuiCheckbox,
} from '@elastic/eui'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchSingleInfluencer, setTalentSheetReaction } from './redux/influencerSlice'
import { Talent } from 'helpers/Talent'
import RenderMarkdown from 'components/RenderMarkdown'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { FaHeart, FaComment, FaEye, FaRegSquare } from 'react-icons/fa'
import { fetchBasicInfo } from 'redux/allSheetsSlice'

import 'react-circular-progressbar/dist/styles.css'
import { safelyMillify } from 'utils'
import 'components/InternalProfile.scss'
import CountryVisitBox from 'components/NewProfile/CountryVisitBox'
import AudienceGenderStats from 'components/NewProfile/AudienceGenderStats'
import SocialMediaStats from 'components/NewProfile/SocialMediaStats'
import AddInfluencerTags from 'components/AddInfluencerTags'
import axios from 'axios'
import { FaTimes } from 'react-icons/fa'
import { setInfluencerTeamSettings } from 'redux/influencerSlice'
import NumberDisplayBox from 'components/NewProfile/NumberDisplayBox'
import CityVisitBox from 'components/NewProfile/CityVisitBox'
import { getStatChanges } from 'utils'
import StatsBox from 'PublicProfiles/v2/StatsBox'
import config from 'config'
import RateCardForm from 'components/RateCardForm'
import { RateList } from 'components/RateCard'

function RenderSinglePost({ post }) {
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const { likes, comments, views } = post?.stat

  const hasStats = likes || comments || views

  return (
    <div className="col-12 col-md-4 mb-4">
      <div
        style={{
          borderRadius: '.5em',
        }}
        className="card p-3"
      >
        <a href={post.link} target="_blank" rel="noreferrer">
          <div className="instagram-post-holder shadow-sm">
            <div
              className="image-holder"
              style={{ backgroundImage: `url(${post.thumbnail})` }}
            ></div>
            {hasStats && (
              <div
                className="d-flex justify-content-between text-black"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent white
                  width: '80%',
                  padding: '10px',
                  fontSize: '0.8em',
                  marginBottom: '15px',
                  borderRadius: '0.5em',
                  left: '50%', // Add this line
                  transform: 'translateX(-50%)', // Add this line
                }}
              >
                <p>
                  <FaHeart /> {safelyMillify(likes)}
                </p>
                <p>
                  <FaComment /> {safelyMillify(comments)}
                </p>
                <p>
                  <FaEye /> {safelyMillify(views)}
                </p>
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  )
}

function RenderPosts({
  selectedSocial,
  setSelectedSocial,
  talent,
  openAccess = true,
  settings = {},
  showPostsType,
  setShowPostsType,
}) {
  const dispatch = useDispatch()

  const influencer = talent?.influencer
  const socials = useSelector((state) => state.socials.entities)

  const displaySocial = useMemo(() => {
    if (!selectedSocial) {
      return null
    }
    return socials[selectedSocial]
  }, [selectedSocial, socials])

  let available_post_types = useMemo(() => {
    if (!selectedSocial) return null
    let available_post_types = [
      {
        display: 'Top',
        id: 'top_posts',
      },
      {
        display: 'Sponsored',
        id: 'commercial_posts',
      },
      {
        display: 'Reels',
        id: 'top_reels',
      },
    ]

    available_post_types = available_post_types.filter((post_type) => {
      const available = _.get(displaySocial, [post_type.id])
      return available && available.length > 0
    })

    return available_post_types
  }, [displaySocial])

  const displayedPosts = useMemo(() => {
    if (!displaySocial) return []
    let posts = _.get(displaySocial, [showPostsType], [])
    const excludedPostIds = _.get(settings, 'excluded_post_ids', [])

    return posts.filter((post) => {
      return !excludedPostIds.includes(post.post_id)
    })
  }, [displaySocial, showPostsType, settings])

  if (!displaySocial) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="container mt-4">
        <EuiButton fill>
          <Link
            style={{
              textDecoration: 'none',
              color: 'white',
            }}
            to={`/talent/${influencer.id}/edit-posts`}
          >
            Show/Hide Posts
          </Link>
        </EuiButton>

        <div className="row influencer-posts-container d-print-none">
          <ul className="nav nav-tabs my-3">
            {available_post_types.map((post_type) => {
              return (
                <li key={`select-${post_type.id}`} className="nav-item">
                  <button
                    className={`nav-link text-black ${
                      showPostsType === post_type.id ? 'active' : ''
                    }`}
                    onClick={() => {
                      setShowPostsType(post_type.id)
                    }}
                  >
                    {post_type.display}
                  </button>
                </li>
              )
            })}
          </ul>
          {!_.isEmpty(displayedPosts) ? (
            displayedPosts.slice(0, 6).map((post) => {
              return <RenderSinglePost key={`post-${post.post_id}`} post={post} />
            })
          ) : (
            <p>No posts available</p>
          )}
        </div>
      </div>
    </div>
  )
}

const EngagementRateBox = ({
  selectedSocial,
  setSelectedSocial,
  title,
  dataKey,
  numberDisplay,
}) => {
  // const socials = useSelector((state) => state.socials.entities)

  // const displaySocial = useMemo(() => {
  //   if (!selectedSocial) {
  //     return null
  //   }
  //   return socials[selectedSocial]
  // }, [selectedSocial])

  return (
    <div className="card p-4 shadow flex-fill">
      <div className="d-flex flex-row">
        <EuiTitle size="xs">
          <h3 className="text-center">{title}</h3>
        </EuiTitle>
      </div>
      <EuiSpacer size="m" />
      <div className="d-flex align-content-center">
        <h4 className="text-center fs-3">{numberDisplay}</h4>
      </div>
    </div>
  )
}

const NewInternalProfile = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const user = useSelector((state) => state.user)

  const selectedInfluencerId = params.influencerId

  const [selectedSocialId, setSelectedSocial] = useState(null)
  const [showPostsType, setShowPostsType] = useState('top_posts')

  const handleSwitchPlatform = (id) => {
    setSelectedSocial(id)
    setShowPostsType('top_posts')
  }

  const influencer = useSelector((state) => state.influencers.entities[selectedInfluencerId])
  const socials = useSelector((state) => state.socials.entities)

  const selectedSocialData = selectedSocialId ? socials[selectedSocialId] : null

  const [editingBoxes, setEditingBoxes] = useState(false)

  const disabledInfoBoxes = _.get(influencer, 'team_settings.disabledInfoBoxes', [])
  const setDisabledInfoBoxes = (disabledInfoBoxes) => {
    dispatch(
      setInfluencerTeamSettings({
        influencer_id: influencer.id,
        updates: { disabledInfoBoxes },
      }),
    )
  }

  // const influencers = useSelector((state) => state.influencers.entities)
  const isLoading = useSelector((state) => state.influencers.loading === 'pending')

  const talent = useMemo(() => {
    if (!influencer) {
      return null
    }

    const talent = new Talent(influencer, socials)
    return talent
  }, [influencer])

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
    // loadTeamNotes()
  }, [dispatch])

  const allSocials = useMemo(() => {
    if (talent) {
      const { allSocials } = talent.getSocials()

      return allSocials
    }
    return []
  }, [talent])

  const reduxSheets = useSelector((state) => state.allSheets.entities)

  useEffect(() => {
    if (allSocials.length > 0 && selectedSocialId === null) {
      // set the selected social to the first social in the list
      setSelectedSocial(allSocials[0].id)
    }
  }, [allSocials])

  const [talentSheetPage, setTalentSheetPage] = useState(1)
  const talentSheetPageSize = 70
  const [toRemove, setToRemove] = useState(null)
  const [editingClientNote, setEditingClientNote] = useState({
    id: null,
    note: '',
  })

  const incrementSheetPage = (direction = 'up') => {
    const maxPage = Math.ceil(influencer.salesheet_ids.length / talentSheetPageSize)
    if (direction === 'up' && talentSheetPage < maxPage) {
      setTalentSheetPage(talentSheetPage + 1)
    } else if (direction === 'down' && talentSheetPage > 1) {
      setTalentSheetPage(talentSheetPage - 1)
    }
  }

  if (!influencer || !influencer?.with_advanced_data) {
    return <div>Loading...</div>
  }

  const averagePlaysData = {
    title: selectedSocialData?.platform === 'instagram' ? 'Average Reels Plays' : 'Average Views',
    value: selectedSocialData?.avg_reels_plays || selectedSocialData?.avg_views,
  }

  const statChanges = getStatChanges(selectedSocialData?.stat_history)

  const topCities = selectedSocialData?.audience_followers?.audience_geo?.cities || []
  const topCountries = selectedSocialData?.audience_followers?.audience_geo?.countries || []

  const widgets = [
    {
      value: 'audience_gender',
      title: 'Audience Gender',
      render: () => {
        return (
          <AudienceGenderStats
            talent={talent}
            selectedSocial={selectedSocialId}
            setSelectedSocial={handleSwitchPlatform}
          />
        )
      },
    },
    {
      value: 'audience_age',
      title: 'Audience Age Groups',
      render: () => {
        return null
        return (
          <EngagementRateBox
            title={'Audience Percentage By Age'}
            dataKey={'audience_age'}
            numberDisplay={safelyMillify(selectedSocialData?.audience_age)}
          />
        )
      },
    },
    {
      value: 'audience_countries',
      title: 'Top Countries',
      render: () => {
        return (
          <CountryVisitBox
            selectedSocial={selectedSocialId}
            setSelectedSocial={handleSwitchPlatform}
          />
        )
      },
    },
    {
      value: 'engagement_rate',
      title: 'Engagement Rate',
      render: () => {
        return (
          <EngagementRateBox
            title={'Engagement Rate'}
            dataKey={'engagement_rate'}
            numberDisplay={`${selectedSocialData?.engagement_rate}%`}
          />
        )
      },
    },
    { value: 'audience_interests', title: 'Audience Interests' },
    {
      value: 'audience_cities',
      title: 'Top Cities',
      render: () => {
        return (
          <CityVisitBox
            selectedSocial={selectedSocialId}
            setSelectedSocial={handleSwitchPlatform}
          />
        )
      },
    },
    { value: 'avg_views', title: 'Average Views', note: 'Youtube / TikTok' },
    { value: 'avg_likes', title: 'Average Likes', note: 'Youtube / TikTok' },
  ]

  return (
    <>
      <EuiPageTemplate>
        <EuiPageTemplate.Section grow={false}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-2 d-flex text-center">
                <div className="profile-image-container">
                  <img
                    style={{
                      width: '90%', // fit the width of the column
                      borderRadius: '5%', // slightly rounded corners
                    }}
                    src={talent.getPhotoUrl()}
                    alt="Profile"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="d-flex flex-column">
                  <EuiTitle size="m">
                    <h1 className="mb-2">{influencer.fullname}</h1>
                  </EuiTitle>
                  <div>
                    <RenderMarkdown markdownText={talent.getDescription()} />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex flex-column flex-md-row justify-content-end">
                  <Link
                    style={{
                      // color: 'inherit',
                      marginBottom: '10px',
                    }}
                    to={`/public/profile/${influencer.id}/team/${user.selectedTeamId}`}
                  >
                    <EuiButton color="text" className="me-2">
                      <span className="text-link-blue">
                        <EuiIcon type="link" size="16px" className="me-1" />
                        Public Profile Link
                      </span>
                    </EuiButton>
                  </Link>
                  <Link
                    data-purpose="talent-custom-description"
                    to={`/talent/edit-description/${influencer.id}`}
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <EuiButton className="me-2 bg-bright-blue" fill>
                      Customize Description
                    </EuiButton>
                  </Link>
                  <Link
                    data-purpose="talent-edit-rates"
                    to={`/talent/edit-rate-card/${influencer.id}`}
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    <EuiButton className="me-2 bg-bright-blue" fill>
                      Rates
                    </EuiButton>
                  </Link>

                  <Link to={`/request/${influencer.id}`}>
                    <EuiButton className="bg-bright-blue" fill>
                      Request Change
                    </EuiButton>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </EuiPageTemplate.Section>
        {influencer.rate_card && (
          <EuiPageTemplate.Section color="subdued">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <RateList rate_card={influencer.rate_card} />
                </div>
              </div>
            </div>
          </EuiPageTemplate.Section>
        )}
        <EuiPageTemplate.Section color="subdued">
          <div
            className="container-fluid"
            // style={{
            //   backgroundColor: '#f1f4fb',
            // }}
          >
            <div className="row">
              <div className="col-12 col-md-4 d-flex mb-3">
                <SocialMediaStats
                  talent={talent}
                  selectedSocial={selectedSocialId}
                  setSelectedSocial={handleSwitchPlatform}
                />
              </div>
              {!disabledInfoBoxes.includes('audience_gender') && (
                <div className="col-12 col-md-4 d-flex mb-3">
                  <AudienceGenderStats
                    talent={talent}
                    selectedSocial={selectedSocialId}
                    setSelectedSocial={handleSwitchPlatform}
                  />
                </div>
              )}

              {!disabledInfoBoxes.includes('audience_countries') && (
                <div className="col-12 col-md-4 d-flex mb-3">
                  <CountryVisitBox
                    selectedSocial={selectedSocialId}
                    setSelectedSocial={handleSwitchPlatform}
                  />
                </div>
              )}
              {!disabledInfoBoxes.includes('avg_views') && averagePlaysData.value && (
                <div className="col-12 col-md-4 d-flex mb-3">
                  <NumberDisplayBox
                    numberDisplay={safelyMillify(averagePlaysData.value)}
                    change={statChanges?.avg_views}
                    selectedSocial={selectedSocialId}
                    title={averagePlaysData.title}
                  />
                </div>
              )}

              {!disabledInfoBoxes.includes('engagement_rate') &&
                selectedSocialData?.engagement_rate && (
                  <div className="col-12 col-md-4 d-flex mb-3">
                    <NumberDisplayBox
                      // dataKey={'avg_reels_plays'}
                      numberDisplay={`${selectedSocialData?.engagement_rate}%`}
                      change={statChanges?.engagement_rate}
                      selectedSocial={selectedSocialId}
                      title={'Engagement Rate'}
                    />
                  </div>
                )}

              {!disabledInfoBoxes.includes('audience_cities') && topCities.length > 0 && (
                <div className="col-12 col-md-4 d-flex mb-3">
                  <CityVisitBox
                    selectedSocial={selectedSocialId}
                    setSelectedSocial={handleSwitchPlatform}
                  />
                </div>
              )}
              {!disabledInfoBoxes.includes('avg_likes') &&
              Number(selectedSocialData?.avg_likes) ? (
                <div className="col-12 col-md-4 d-flex mb-3">
                  <NumberDisplayBox
                    // dataKey={'avg_reels_plays'}
                    numberDisplay={safelyMillify(selectedSocialData?.avg_likes)}
                    change={statChanges?.avg_likes}
                    selectedSocial={selectedSocialId}
                    title={'Average Likes'}
                  />
                </div>
              ) : null}
              {!disabledInfoBoxes.includes('audience_age') && (
                <div className="col-12 col-md-4 mb-3">
                  <StatsBox
                    shadow={'shadow-sm'}
                    infoKey={'audience_age'}
                    audience_followers={selectedSocialData?.audience_followers}
                    displaySettings={{
                      displayAccentColor: config.colors['bright-blue'],
                    }}
                    className="card"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <>
              <div className="d-flex flex-row justify-content-center">
                {editingBoxes && (
                  <ul className="list-group w-50 my-2">
                    {widgets.map((item) => {
                      return (
                        <>
                          <li key={`key-${item.value}`} className="list-group-item">
                            <EuiCheckbox
                              id={`checkbox-${item.value}`}
                              label={item.title}
                              checked={!disabledInfoBoxes.includes(item.value)}
                              onChange={(e) => {
                                if (disabledInfoBoxes.includes(item.value)) {
                                  setDisabledInfoBoxes(
                                    disabledInfoBoxes.filter((k) => k != item.value),
                                  )
                                } else {
                                  setDisabledInfoBoxes([...disabledInfoBoxes, item.value])
                                }
                              }}
                            />
                          </li>
                        </>
                      )
                    })}
                  </ul>
                )}
              </div>
              <EuiButton
                onClick={() => {
                  if (editingBoxes) {
                    axios.post('/api/team/influencer-settings', {
                      influencer_id: influencer.id,
                      settings: influencer.team_settings,
                    })
                  }
                  setEditingBoxes(!editingBoxes)
                }}
                style={{ width: '180px' }}
                className="btn btn-primary"
              >
                {editingBoxes ? 'Save' : 'Show/Hide Widgets'}
              </EuiButton>
            </>
          </div>
          <div className="row mt-5">
            <h2 className="fs-2 fw-bold">Talent Posts</h2>
            <RenderPosts
              selectedSocial={selectedSocialId}
              setSelectedSocial={setSelectedSocial}
              showPostsType={showPostsType}
              setShowPostsType={setShowPostsType}
              talent={talent}
              settings={influencer.team_settings}
            />
          </div>
        </EuiPageTemplate.Section>

        <EuiPageTemplate.Section>
          <div className="container mt-4">
            <h3>Tags</h3>
            <div className="w-50">
              <AddInfluencerTags influencer_id={params.influencerId} />

              <p>
                Note: Your team may use pre-defined tags or any custom tags you choose. Custom tags
                are marked with an asterisk/black background. Tags using a blue background are
                searchable by other users if your team's roster is available on the Talent Pitch
                Pro search tool
              </p>
            </div>
          </div>
        </EuiPageTemplate.Section>
        {!_.isEmpty(influencer.salesheet_ids) && (
          <EuiPageTemplate.Section>
            <div className="container mt-4">
              <h3 className="">Talent Sheets ({influencer.salesheet_ids.length} total)</h3>
              {talentSheetPageSize < influencer.salesheet_ids.length && (
                <>
                  <div className="mb-2">
                    <p className="text-bold my-2">
                      Showing {1 + (talentSheetPage - 1) * talentSheetPageSize}-
                      {Math.min(
                        talentSheetPage * talentSheetPageSize,
                        influencer.salesheet_ids.length,
                      )}
                    </p>

                    <button
                      className="btn btn-light mb-3 me-3"
                      onClick={() => incrementSheetPage('down')}
                    >
                      {`Previous`}
                    </button>
                    <button
                      className="btn btn-light mb-3"
                      onClick={() => incrementSheetPage('up')}
                    >
                      {`Next`}
                    </button>
                  </div>
                </>
              )}

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="">
                      Title
                    </th>
                    <th scope="col" className="text-center">
                      Accepted by Client
                    </th>
                    <th style={{ width: '40%' }} scope="col" className="text-center">
                      Notes
                    </th>
                    <th scope="col" className="text-center">
                      Link
                    </th>
                    {toRemove && (
                      <th scope="col" className="text-center">
                        Remove
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {influencer.salesheet_ids
                    .slice(
                      (talentSheetPage - 1) * talentSheetPageSize,
                      talentSheetPage * talentSheetPageSize,
                    )
                    .map((sheet_id, sheet_index) => {
                      const sheet = reduxSheets[sheet_id]
                      const sheet_notes = _.get(influencer, ['sheet_notes', sheet_id], {})
                      if (!sheet) return null
                      return (
                        <tr key={`sheet-${sheet.id}`}>
                          <td>{sheet.title}</td>
                          <td
                            className="selectable text-center"
                            onClick={() => {
                              axios
                                .post('/api/user/influencer/talent-sheet/client-response', {
                                  sheet_id: sheet.id,
                                  influencer_id: params.influencerId,
                                  client_accepted: !sheet_notes.client_accepted,
                                })
                                .then((res) => {
                                  dispatch(
                                    setTalentSheetReaction({
                                      sheet_id,
                                      influencer_id: params.influencerId,
                                      updates: {
                                        client_accepted: res.data.client_accepted,
                                      },
                                    }),
                                  )

                                  // dispatch(fetchInfluencer(params.influencerId))
                                })
                            }}
                          >
                            {!!sheet_notes.client_accepted && '✔'}
                          </td>
                          <td>
                            {editingClientNote.id === sheet.id ? (
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault()
                                  axios
                                    .post('/api/user/influencer/talent-sheet/client-response', {
                                      sheet_id: sheet.id,
                                      influencer_id: params.influencerId,
                                      client_note: editingClientNote.note,
                                    })
                                    .then((res) => {
                                      dispatch(
                                        setTalentSheetReaction({
                                          sheet_id,
                                          influencer_id: params.influencerId,
                                          updates: {
                                            client_note: res.data.client_note,
                                          },
                                        }),
                                      )
                                      setEditingClientNote({
                                        id: null,
                                        note: '',
                                      })
                                    })
                                }}
                              >
                                <div className="d-flex flex-row align-content-center">
                                  <EuiTextArea
                                    // className="form-control"
                                    style={{
                                      height: '6em',
                                    }}
                                    placeholder="Add Note"
                                    value={editingClientNote.note || ''}
                                    onChange={(e) =>
                                      setEditingClientNote({
                                        ...editingClientNote,
                                        note: e.target.value,
                                      })
                                    }
                                  />
                                  <EuiButton size="s" className="ms-2" type="submit">
                                    Save
                                  </EuiButton>
                                </div>
                              </form>
                            ) : (
                              <div
                                className="selectable"
                                onClick={() => {
                                  setEditingClientNote({
                                    id: sheet.id,
                                    note: sheet.client_note,
                                  })
                                }}
                              >
                                {_.get(influencer, ['sheet_notes', sheet_id, 'client_note']) ? (
                                  <p className="text-center">
                                    {influencer.sheet_notes[sheet_id].client_note}
                                  </p>
                                ) : (
                                  <p className={`text-center`} style={{ fontStyle: 'italic' }}>
                                    Click to add note
                                  </p>
                                )}
                              </div>
                            )}
                          </td>
                          <td>
                            <Link to={`/talent-sheet/${sheet.id}`}>Link</Link>
                          </td>
                          {toRemove && (
                            <td
                              className="text-center selectable"
                              onClick={() => {
                                if (toRemove.includes(sheet.id)) {
                                  setToRemove(toRemove.filter((id) => id !== sheet.id))
                                } else {
                                  setToRemove([...toRemove, sheet.id])
                                }
                              }}
                            >
                              {toRemove.includes(sheet.id) ? <FaTimes /> : <FaRegSquare />}
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>

              <div className="d-flex w-100 justify-content-end mb-3">
                {toRemove === null ? (
                  <EuiButton
                    fill
                    onClick={() => {
                      setToRemove([])
                    }}
                  >
                    Remove from Sheets
                  </EuiButton>
                ) : (
                  <>
                    <EuiButton
                      className="me-3"
                      onClick={() => {
                        console.log('cancel removing')
                        console.log('toRemove', toRemove)
                        setToRemove(null)
                      }}
                    >
                      Cancel
                    </EuiButton>
                    <EuiButton
                      color="danger"
                      className=""
                      onClick={() => {
                        if (toRemove.length === 0) return
                        if (
                          !window.confirm(
                            'Are you sure you want to remove the influencer from these sheets?',
                          )
                        )
                          return
                        axios
                          .post('/api/user/talent-sheets/remove-influencer', {
                            influencer_id: params.influencerId,
                            sheet_ids: toRemove,
                          })
                          .then((res) => {
                            dispatch(fetchBasicInfo())
                            dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))

                            setToRemove(null)
                          })
                      }}
                    >
                      Remove from Selected Sheets
                    </EuiButton>
                  </>
                )}
              </div>
            </div>
          </EuiPageTemplate.Section>
        )}
      </EuiPageTemplate>
    </>
  )
}

export default NewInternalProfile
