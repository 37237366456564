import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import RateCard from './RateCard'
import { EuiPageTemplate } from '@elastic/eui'
import { useEuiTheme } from '@elastic/eui'
import { BiPlusCircle, BiTrash } from 'react-icons/bi'
import { fetchSingleInfluencer } from '../redux/influencerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast, Slide } from 'react-toastify'

const testRowEmpty = (cardRow) => {
  return cardRow.platform === '' && cardRow.description === '' && cardRow.rate === ''
}

const testCardEmpty = (rateCard) => {
  return rateCard.every(testRowEmpty)
}

const RateCardForm = ({ initialRateCard = [{ platform: '', description: '', rate: '' }] }) => {
  const dispatch = useDispatch()
  const params = useParams()
  const [rateCard, setRateCard] = useState(initialRateCard)
  const influencer = useSelector((state) => state.influencers.entities[params.influencerId])

  const showSavedToast = ({ customText = 'Custom Description Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const { euiTheme } = useEuiTheme()

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
  }, [params.influencerId])

  useEffect(() => {
    if (influencer && influencer.rate_card) {
      setRateCard(influencer.rate_card)
    }
  }, [influencer])

  const onSubmit = async (rateCard) => {
    const data = {
      rate_card: rateCard,
      influencer_id: params.influencerId,
      removeRateCard: testCardEmpty(rateCard),
    }

    const response = await axios.post('/api/user/talent/rate-card', data)

    if (response.data === 'OK') {
      showSavedToast({ customText: 'Rate Card Saved' })
    }
  }

  const platformOptions = [
    { value: 'instagram', label: 'Instagram' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'other', label: 'Other' },
  ]

  const handleAddRow = () => {
    setRateCard([...rateCard, { platform: '', description: '', rate: '' }])
  }

  const handleRemoveRow = (index) => {
    const updatedRateCard = rateCard.filter((_, i) => i !== index)
    setRateCard(updatedRateCard)
  }

  const handleInputChange = (index, field, value) => {
    const updatedRateCard = rateCard.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value }
      }
      return item
    })
    setRateCard(updatedRateCard)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(rateCard)
  }

  return (
    <>
      <EuiPageTemplate restrictWidth="90%">
        <EuiPageTemplate.Header
          style={{
            borderBottom: '0',
          }}
          pageTitle={`Rate Card${influencer ? `: ${influencer.fullname}` : ''}`}
        />
        <div
          style={{
            margin: '10px 20px',
            backgroundColor: euiTheme.colors.lightestShade,
          }}
        >
          <EuiPageTemplate.Section
            style={{
              borderRadius: '10px',
            }}
          >
            <div className="container">
              <Form onSubmit={handleSubmit}>
                {rateCard.map((item, index) => (
                  <Row key={index} className="mb-3">
                    <Col md={3}>
                      <Form.Control
                        as="select"
                        value={item.platform}
                        onChange={(e) => handleInputChange(index, 'platform', e.target.value)}
                        required
                      >
                        <option value="">Select Platform</option>
                        {platformOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={5}>
                      <Form.Control
                        type="text"
                        placeholder="Description (e.g. Story, Feed Post)"
                        value={item.description}
                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        required
                      />
                    </Col>
                    <Col md={2}>
                      <Form.Control
                        type="text"
                        placeholder="Rate"
                        value={item.rate}
                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                        required
                      />
                    </Col>
                    <Col md={2}>
                      <Button variant="danger" onClick={() => handleRemoveRow(index)}>
                        <BiTrash className="me-2" /> Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="row d-flex justify-content-end">
                  <div className="col-2">
                    <Button variant="secondary" onClick={handleAddRow} className="mb-3">
                      <BiPlusCircle className="me-2" /> Add Row
                    </Button>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    style={{
                      fontSize: '1.2em',
                    }}
                    type="submit"
                  >
                    Save Rate Card
                  </button>
                </div>
              </Form>
            </div>
            <div className="container mb-3">
              {!testCardEmpty(rateCard) && (
                <div className="w-100 text-center">
                  <RateCard
                    rate_card={rateCard}
                    accentColor="tpp-blue"
                    buttonText="Show Preview"
                  />
                </div>
              )}
            </div>
          </EuiPageTemplate.Section>
        </div>
      </EuiPageTemplate>
    </>
  )
}

export default RateCardForm
